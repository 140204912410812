'use client'

import Spline from '@splinetool/react-spline'

import type { StyleProps } from '~/lib/types'

import { useMediaQuery } from '~/hooks/useMediaQuery'

const SplineMap = ({ style, className }: StyleProps) => {
	const isMobile = useMediaQuery(1024)

	if (isMobile) {
		return null
	}

	return (
		<Spline
			style={style}
			className={className}
			scene="https://prod.spline.design/Dg3pv63pqCHcSjZE/scene.splinecode"
		/>
	)
}

export { SplineMap }
